<template>
  <div>
    <div class="receiving-address">
      <b-container fluid>
        <div class="title-wrap">
          <h2 class="page-title">收货地址</h2>
          <span @click="openAddAddressWindow">+添加地址</span>
        </div>
        <div class="address-wrap">
          <table>
            <thead>
              <tr>
                <th>收货人</th>
                <th>收货地址</th>
                <th>手机号码</th>
                <th>操作</th>
                <th>设置默认地址</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in userAddressList" :key="index">
                <td>{{item.name}}</td>
                <td>{{item.province_name + item.city_name + item.area_name + item.detail_address}}</td>
                <td>{{item.phone.replace(/^(\d{3})\d{4}(\d+)/,"$1****$2")}}</td>
                <td>
                  <div class="operation">
                    <span class="del" @click="del(item.address_id)">删除</span>
                  </div>
                </td>
                <td>{{item.is_default ? "是" : '否'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-container>
    </div>
    <el-dialog title="添加收货地址" :visible.sync="dialogVisible" width="40%" center>
      <el-form :model="form" label-width="100px">
        <el-form-item label="选择地址：">
          <li-area-select v-model="defultAddress"></li-area-select>
        </el-form-item>
        <el-form-item label="详细地址：">
          <el-input v-model="form.detailedAddress"></el-input>
        </el-form-item>
        <el-form-item label="收货人：">
          <el-input v-model="form.consignee"></el-input>
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="warning" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import liAreaSelect from "@/components/liAreaSelect";
  export default {
    components: {
      liAreaSelect
    },
    data() {
      return {
        dialogVisible: false,
        //默认值设置，可为空
        defultAddress: {
          sheng: '',
          shi: '',
          qu: '',
        },
        form: {
          detailedAddress: "",
          consignee: "",
          phone: ""
        },
        token: "",
        userAddressList: []
      }
    },
    created() {
      this.init();
    },
    methods: {
      init() {
        this.token = localStorage.getItem("token");
        this.getUserAddress();
      },
      // 获取用户地址
      getUserAddress() {
        this.$api.getUserAddress({
          token: this.token
        }).then(res => {
          if (res.status == 100) {
            this.userAddressList = res.data;
          }
        }).catch(err => console.error(err));
      },
      // 打开添加地址窗口
      openAddAddressWindow() {
        this.dialogVisible = !this.dialogVisible;
      },
      // 添加地址
      submit() {
        if (this.defultAddress.sheng == '') {
          return this.$message.error('请选择您所在的城市');
        }
        if (this.defultAddress.shi == '') {
          return this.$message.error('请选择您所在城市的市区');
        }
        if (this.defultAddress.qu == '') {
          return this.$message.error('请选择您所在城市市区的区域');
        }
        if (this.form.detailedAddress == '') {
          return this.$message.error('请输入您所在地区的详细地址');
        }
        if (this.form.consignee == '') {
          return this.$message.error('请输入收货人姓名');
        }
        if (this.form.phone == '') {
          return this.$message.error('请输入收货人手机号码');
        }else{
          const phoneReg = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/;
          if(!phoneReg.test(this.form.phone)) return this.$message.error('请输入正确的手机号码');
        }

        const provinceId = this.defultAddress.sheng.split(",")[1],
          cityId = this.defultAddress.shi.split(",")[1],
          areaId = this.defultAddress.qu.split(",")[1];

        // 保存地址
        this.$api.saveUserAddress({
          province_id: provinceId,
          city_id: cityId,
          area_id: areaId,
          detail_address: this.form.detailedAddress,
          name: this.form.consignee,
          phone: this.form.phone,
          token: this.token
        }).then(res => {
          if (res.status == 100) {
            // 关闭添加地址弹窗
            this.openAddAddressWindow();
            // 清空表单数据
            for (let key in this.defultAddress) {
              this.defultAddress[key] = "";
            }
            for (let key in this.form) {
              this.form[key] = "";
            }
            
            this.$message({
              type: 'success',
              message: '地址添加成功!'
            });
            this.getUserAddress();
          }
        }).catch(err => console.error(err))
      },
      // 删除地址
      del(id) {
        this.$confirm('您确定要删除改地址吗?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api.delUserAddress({
            address_id: id,
            token: this.token
          }).then(res => {
            if(res.status == 100){
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getUserAddress();
            }
          }).catch(err => console.error(err));
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/assets/scss/userCenterCommon";
  @import "@/view/userCenter/receivingAddress/receivingAddress";
</style>
